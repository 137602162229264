import { flatten } from 'lodash'
import { firstBy } from 'thenby'
// import { sum } from 'lodash.sum'
import DtoUpdate from './DtoUpdate'

export default class ParentTeam {
  constructor (dto, tournament) {
    this.tournament = tournament
    this.pairs2 = []
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (dto) {
      if (typeof dto === 'string') dto = JSON.parse(dto)
      const exclude = []
      DtoUpdate(dto, this, exclude)
    }

    const teams = this.tournament.activeTeams.filter(f => f.rosteredTeamId === this.id)
    const teamIds = teams.map(m => m.id)
    const matches = this.tournament.matches.filter(f => !f.isBye && f.activeTeamIds.some(id => teamIds.includes(id))).sort(firstBy('unix'))
    this.pairs2 = []
    matches.forEach(m => {
      // var lups = []
      if (m.homeIds.some(id => teamIds.includes(id))) {
        var h = m.getLineupPlayers(true, teams, true, 2)
        this.addMatch(h)
      }
      if (m.awayIds.some(id => teamIds.includes(id))) {
        var a = m.getLineupPlayers(false, teams, true, 2)
        this.addMatch(a)
      }
    })
  }

  addMatch (dto) {
    var x = this.pairs2.find(f => f.name === dto.name)
    if (x) {
      x.w += dto.w
      x.l += dto.l
      x.u += dto.u
    } else {
      this.pairs2.push(dto)
    }
  }

  get divisions () {
    return this.tournament.publicDivisions.filter(f => !!f.activeTeams.find(t => t.rosteredTeamId === this.id))
  }

  get children () {
    return this.tournament.activeTeams.filter(f => f.rosteredTeamId === this.id)
  }

  get squads () {
    return flatten(
      this.divisions
        .filter(f => f.isDuals)
        .map(d =>
          d.activeTeams
            .filter(f => f.rosteredTeamId === this.id)
            .map(t => {
              return {
                team: t,
                division: d,
                matches: this.tournament.matches.filter(f => f.activeTeamIds.includes(t.id)),
                get record () {
                  return {
                    w: this.matches.filter(f => f.complete && f.winningId === this.team.id),
                    l: this.matches.filter(f => f.complete && f.winningId !== this.team.id)
                  }
                }
              }
            })
        )
    ).sort(firstBy('team.name'))
  }

  get squadIds () {
    return this.squads.map(m => m.id)
  }

  get pairsOG () {
    const teams = flatten(
      this.divisions
        .filter(f => !f.isDuals)
        .map(d =>
          d.activeTeams
            .filter(f => f.rosteredTeamId === this.id)
            .map(t => {
              return {
                name: t.name,
                team: t,
                division: d,
                matches: this.tournament.matches.filter(f => !f.isBye && f.activeTeamIds.includes(t.id)).sort(firstBy('unix')),
                get record () {
                  return {
                    w: this.matches.filter(f => f.complete && f.winningTeam && f.winningTeam.teamId === this.team.id).sort(firstBy('unix')),
                    l: this.matches.filter(f => f.complete && f.winningTeam && f.winningTeam.teamId !== this.team.id).sort(firstBy('unix'))
                  }
                },
                get unplayed () {
                  return this.matches.filter(f => !f.complete).sort(firstBy('unix'))
                }
              }
            })
        )
    )

    const result = {
      teams: teams.sort(sortByTeamNumber),
      matches: flatten(teams.map(m => m.matches)).sort(firstBy('unix')),
      get record () {
        return {
          w: flatten(this.teams.map(m => m.record.w)),
          l: flatten(this.teams.map(m => m.record.l))
        }
      },
      get unplayed () {
        return this.matches.filter(f => !f.complete)
      }
    }
    return result
  }

  get pairs () {
    const teams = this.pairs2.map(t => {
      return {
        name: t.name,
        team: t,
        matches: this.tournament.matches.filter(f => !f.isBye && f.activeTeamIds.includes(t.id)).sort(firstBy('unix')),
        record: {
          w: t.w,
          l: t.l
        },
        unplayed: t.u
      }
    })

    const result = {
      teams: teams.sort(sortByWinLoss),
      record: {
        w: teams.map(m => m.record.w).reduce((a, b) => { return a + b }, 0),
        l: teams.map(m => m.record.l).reduce((a, b) => { return a + b }, 0)
      },
      unplayed: teams.map(m => m.u).reduce((a, b) => { return a + b }, 0)
    }
    return result
  }

  get pairIds () {
    return this.pairs.map(m => m.id)
  }
}
function sortByTeamNumber (a, b) {
  const x = +(a.name.replace(/\D/g, ''))
  const y = +(b.name.replace(/\D/g, ''))
  return x - y
}

function sortByWinLoss (a, b) {
  const x = a.record.l === 0 ? a.record.w * 100 : a.record.w > 0 ? a.record.w / a.record.l : 0
  const y = b.record.l === 0 ? b.record.w * 100 : b.record.w > 0 ? b.record.w / b.record.l : 0
  return y - x
}
