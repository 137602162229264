import { firstBy } from 'thenby'
import uniqBy from 'lodash.uniqby'
import ParentTeam from './ParentTeam'
import ParentTeamClub from './ParentTeamClub'

export default class TournamentSummarizer {
  loading = false
  parents = []

  constructor (tournament, api) {
    this.api = api
    this.tournament = tournament
    this.loadParents()
  }

  get teams () {
    return this.tournament.activeTeams.filter(f => f.isRostered)
  }

  get parentIds () {
    return [...new Set(this.teams.map(m => m.rosteredTeamId))]
  }

  get clubs () {
    return this.tournament.isNcaa ? [] : this.parents
  }

  recalc () {
    this.parents.forEach(p => {
      p.update()
    })
  }

  loadParents () {
    if (this.parentIds.length === 0) return
    this.loading = true
    this.api.roster.teamsById(this.parentIds)
      .then(r => {
        if (this.tournament.isNcaa) {
          this.initNcaa(r.data)
        } else {
          this.initClubs(r.data)
        }
      })
      .catch(e => console.log(e.response))
      .finally(() => {
        this.loading = false
      })
  }

  initNcaa (teams) {
    this.parents = teams.map(m => new ParentTeam(m, this.tournament, this.api)).sort(firstBy('name'))
  }

  initClubs (teams) {
    let clubs = teams.map(m => {
      return {
        id: m.ownerId,
        name: m.owner || 'Unaffiliated',
        logoUrl: m.logoUrl
      }
    })

    clubs = uniqBy(clubs, 'id').sort(firstBy('name'))
    clubs.forEach(c => {
      const t = new ParentTeamClub(c, this.tournament, this.api)
      const children = teams.filter(f => f.ownerId === c.id)
      if (children.length > 0) {
        t.rtIds.push(...children.map(m => m.id))
      }

      this.parents.push(t)
    })
  }
}
