<template>
  <v-expand-transition>
    <v-card flat :dark="dark">
      <v-card-text class="pa-0">
        <div>{{round.name}} {{this.bracket? this.playoffRound : ''}}</div>
        <bracket-match-card
          v-if="division"
          :matchIn="match"
          :pool="pool"
          :bracket="bracket"
          :bracketCard="!!bracket"
          :_division="division"
          :_round="round"
          :nums="true"
          :lowlight="false"
          :lineupLock="lineupLock"
        ></bracket-match-card>
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>
import BracketMatchCard from '@/components/Tournament/Match/BracketMatchCard'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: ['match', 'divisionId', 'dark'],
  data () {
    return {
      refreshKey: 1,
      timeOut: null
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'userTeams']),
    pool () {
      return this.match.poolId ? this.tournament.pools.find(f => f.id === this.match.poolId) : null
    },
    bracket () {
      return this.match.bracketId ? this.tournament.brackets.find(f => f.id === this.match.bracketId) : null
    },
    round () {
      return this.pool ? this.tournament.rounds.find(r => {
        return r.pools.find(p => p.id === this.pool.id)
      }) : this.bracket ? this.tournament.rounds.find(r => {
        return r.brackets.find(p => p.id === this.bracket.id)
      }) : null
    },
    division () {
      return this.divisionId ? this.tournament.divisions.find(d => d.id === this.divisionId) : this.calcDivision
    },
    calcDivision () {
      return this.tournament.divisions.find(d => {
        return d.rounds.find(f => f.id === this.round.id)
      })
    },
    playoffRound () {
      const a = this.bracket.matches.filter(f => f.round === this.match.round)
      if (a) {
        if (a.length === this.bracket.champions) return 'Final Round'
        switch (a.length) {
          case 1:
            return this.bracket.type && this.bracket.type.includes('4') ? 'Medal Matches' : 'Finals'
          case 2:
            return this.bracket.type && this.bracket.type === 'DOUBLE_ELIM_CROSSOVER' ? 'Crossover' : 'Semis'
          case 4:
            return 'Quarters'
          default:
            return `Round of ${a.length}`
        }
      }
      return '???'
    },
    lineupLock () {
      return this.refreshKey && this.division.lineupLockObj(this.match.startTime, this.view, this.userTeams)
    }
  },
  methods: {
    checkSetRefresh () {
      if (this.lineupLock.hasLock && this.lineupLock.isLocked) return

      const d = moment(this.lineupLock.dtLock).diff(moment())
      this.timeOut = setTimeout(() => {
        this.refreshKey++
      }, d)
    }
  },
  mounted () {
    this.checkSetRefresh()
  },
  destroyed () {
    if (this.timeOut) clearTimeout(this.timeOut)
  },
  watch: {
    'lineupLock.dtLock': 'checkSetRefresh'
  },
  components: {
    BracketMatchCard
  }
}
</script>
