import { flatten } from 'lodash'
import { firstBy } from 'thenby'
import DtoUpdate from './DtoUpdate'

export default class ParentTeam {
  rtIds = []

  constructor (dto, tournament, api) {
    this.api = api
    this.tournament = tournament
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = []
    DtoUpdate(dto, this, exclude)
  }

  get divisions () {
    return this.tournament.publicDivisions.filter(f => !!f.activeTeams.find(t => this.rtIds.includes(t.rosteredTeamId)))
  }

  get children () {
    return this.tournament.activeTeams.filter(f => this.rtIds.includes(f.rosteredTeamId))
  }

  get squads () {
    const tourney = this.tournament
    const teams = flatten(
      this.divisions
        .filter(f => f.isDuals)
        .map(d =>
          d.activeTeams
            .filter(f => this.rtIds.includes(f.rosteredTeamId))
            .map(t => {
              const duals = tourney.duals.filter(f => !f.isBye && f.activeTeamIds.includes(t.id))
              return {
                name: t.name,
                team: t,
                division: d,
                matches: duals.sort(firstBy('unix')),
                get record () {
                  return {
                    w: this.matches.filter(f => f.complete && f.winningTeam && f.winningTeam.teamId === this.team.id).sort(firstBy('unix')),
                    l: this.matches.filter(f => f.complete && f.winningTeam && f.winningTeam.teamId !== this.team.id).sort(firstBy('unix'))
                  }
                },
                get unplayed () {
                  return this.matches.filter(f => !f.complete).sort(firstBy('unix'))
                }
              }
            })
        )
    )

    const result = {
      teams: teams.sort(firstBy('name')),
      matches: flatten(teams.map(m => m.matches)).sort(firstBy('unix')),
      get record () {
        return {
          w: flatten(this.teams.map(m => m.record.w)),
          l: flatten(this.teams.map(m => m.record.l))
        }
      },
      get unplayed () {
        return this.matches.filter(f => !f.complete)
      }
    }
    return result
  }

  get squadIds () {
    return this.squads.map(m => m.id)
  }

  get pairs () {
    const teams = flatten(
      this.divisions
        .filter(f => !f.isDuals)
        .map(d =>
          d.activeTeams
            .filter(f => this.rtIds.includes(f.rosteredTeamId))
            .map(t => {
              return {
                name: t.name,
                team: t,
                division: d,
                matches: this.tournament.matches.filter(f => !f.isBye && f.activeTeamIds.includes(t.id)).sort(firstBy('unix')),
                get record () {
                  return {
                    w: this.matches.filter(f => f.complete && f.winningTeam && f.winningTeam.teamId === this.team.id).sort(firstBy('unix')),
                    l: this.matches.filter(f => f.complete && f.winningTeam && f.winningTeam.teamId !== this.team.id).sort(firstBy('unix'))
                  }
                },
                get unplayed () {
                  return this.matches.filter(f => !f.complete).sort(firstBy('unix'))
                }
              }
            })
        )
    )

    const result = {
      teams: teams.sort(sortByTeamNumber),
      matches: flatten(teams.map(m => m.matches)).sort(firstBy('unix')),
      get record () {
        return {
          w: flatten(this.teams.map(m => m.record.w)),
          l: flatten(this.teams.map(m => m.record.l))
        }
      },
      get unplayed () {
        return this.matches.filter(f => !f.complete)
      }
    }
    return result
  }

  get pairIds () {
    return this.pairs.map(m => m.id)
  }
}
function sortByTeamNumber (a, b) {
  const x = +(a.name.replace(/\D/g, ''))
  const y = +(b.name.replace(/\D/g, ''))
  return x - y
}
